@import 'libs/styles/src/common/common';

@layer component {
  .landing {
    margin-bottom: 3rem;
    background: $dark-blue;

    @include media-breakpoint-down(sm) {
      margin-bottom: 2rem;
    }
  }

  .landing-container {
    @include media-breakpoint-up(xxl) {
      max-width: 1300px;
    }
  }

  .search-header {
    @include media-breakpoint-only(sm) {
      font-size: 28px;
      line-height: 2.25rem;
    }

    @include media-breakpoint-only(xs) {
      font-size: 24px;
      line-height: 2.25rem;
    }

    margin: 2rem 0 1rem;
    color: $white;
    font-weight: 700;
    font-size: 32px;
    line-height: 3rem;
  }

  .emphasis {
    font-weight: bold;
  }

  .search-bar {
    margin-bottom: 2.5rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 1.6rem;
    }

    &__form {
      background-color: #fff;
      border-radius: 1rem;
      padding: 24px;

      @include media-breakpoint-down(md) {
        padding: 18px;
      }
    }

    .form-container {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        row-gap: 1em;
      }
    }

    .form-input-group {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        row-gap: 0.8em;
      }
    }

    .form-submit-button {
      margin-left: auto;

      @include media-breakpoint-down(md) {
        align-self: flex-end;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .form-submit-button-content {
      font-size: 16px;
      font-weight: 700;
      padding: 0.75rem 2rem;
      margin: 0;
    }

    .form-input-error {
      background-color: rgba($red, 0.15);
    }

    .form-input {
      padding: 0;
      margin: 0;
    }

    .form-input-field {
      color: $dark-blue;
      min-width: 200px;
      padding: 0.75rem 0.5rem;

      @include media-breakpoint-down(md) {
        min-width: unset;
        padding: 0.6rem 0.5rem;
      }
    }

    .form-input-icon {
      padding-left: 0.75rem;
      min-width: 2.25rem;
      text-align: center;
    }

    .form-error {
      color: $red;
      height: 0;
      font-size: 14px;

      @include media-breakpoint-down(md) {
        height: auto;
        margin-bottom: 0.5rem;
      }
    }

    .form-field {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 1rem;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0;
      }
    }

    .form-field-dropdown {
      top: calc(100% + 0.5rem);
    }

    .form-menu-item {
      padding: 0.6rem 1rem;
    }
  }
}
